.App-link {
  color: #61dafb;
}

/* make smaller to avoid getting cut off by mobile safari toolbar on my phone*/
.App {
  height: 85vh;
  min-height: 500px;
}

@media (min-width: 500px) {
  .App {
    height: 90vh;
  }
}

.details-col {
  height: 25%;
}
.sitemap-col {
  height: 75%;
}

@media (min-width: 992px) {
  .details-col {
    height: 100%;
  }
  .sitemap-col {
    height: 100%;
  }
}

.main-row {
  height: calc(100% - 42px); /* 42px=height of navbar. Guessing this is hacky */
}

.search-results {
  max-height: 300px;
  overflow: scroll;
}
button.win95-close {
  padding: 0px;
}

.card-header > h5 {
  /* want semantic meaning of header tag but not the big text*/
  font-size: small;
  font-weight: bold;
  margin-bottom: 0;
}
